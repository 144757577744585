import React from "react";
import './Header.css'
// import animatedlogo from './logodesign2.png'
// import filogo from '../assects/fina logo.jpeg'
// import index from '../assects/indexlogo.jpeg'
// import logo from '../assects/Logo_forest.PNG'
import Logo from '../assects/Statelogo.webp'

const Header = () => {
    return (
        <div className="homepg">
            <div className="head">
                <h1>Estate X <br />
                </h1>
                <br />
                <h3>"Unlocking Dreams, One Home at a Time."</h3>
            </div>
            <div className="animate-logo">
                <img src={Logo} alt=" animated logo" />
            </div>
        </div>
    );
};

export default Header;