import React from "react";
import { Link } from 'react-router-dom';
import './OurProjects.css';

import Vijay from '../assects/Road.jpeg'
import Coridoor from '../assects/Railway .jpeg'
import Bicholi from '../assects/Bridges.jpeg'

// import dam from '../assects/Dam.jpeg'
// import watersupply from '../assects/water_supply.jpeg'
// import Rehanitation from '../assects/Rehabitation.jpeg'

const Ourproduct = () => {
    return (
        <div id="our products">
            <div className="products">
                <div className="header">
                    <h1>EState X PVT LTD</h1>
                    <h2>Incorporated in the year 1977</h2>
                </div>
                <div>
                    <div className="productrow">

                        <div className="para">
                            <p>At Estate X, we are dedicated to helping you find the perfect property
                                that meets your needs and desires. As a trusted name in the real estate industry,
                                we specialize in guiding individuals and businesses through the complex process
                                of purchasing property, ensuring a smooth and satisfying experience from start to finish.</p>

                            <div className="property-head">
                                <h2>Our Properties</h2>
                            </div>
                        </div>
                        <div className="img-wrap">

                            <div className="product-box"><div>
                                <div className="img-container">
                                    <div className="img-contant">
                                        <img className='ashwagandha1-img' src={Vijay} alt="this is img 1"></img>
                                        <div className="overlay">
                                            <p>
                                            </p></div>
                                    </div>
                                </div>

                                <li className="product-title"><Link to="/Vijay-Nagar">Vijay Nagar</Link></li>
                                <div className="hover-text">Text on hover</div>
                            </div>
                            </div>
                            <div className="product-box">
                                <div className="img-container">
                                    <img src={Coridoor} alt=""></img>
                                    <div className='overlay2'>
                                        <p>

                                        </p>
                                    </div>
                                </div>
                                <li className="product-title"><Link to="/Super-Coridoor">Super Coridoor</Link></li>
                            </div>
                            <div className="product-box">
                                <div className="img-container">
                                    <img src={Bicholi} alt=""></img>
                                    <div className='overlay3'><p>

                                    </p></div>
                                </div>
                                <li className="product-title"><Link to="/Bicholi">Bicholi</Link></li>

                            </div>
                        </div>

                    </div>
                    {/* <div className="productrow">
                        <div className="img-wrap">
                            <div className="product-box">
                                <div className="img-container"> <img src={dam} alt=""></img>
                                    <div className='overlay'><p>
                                    </p></div>
                                </div>

                                <li className="product-title"><Link to="/">Palsigar</Link></li>
                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={watersupply} alt=""></img>
                                    <div className='overlay2'><p>

                                    </p></div>
                                </div>

                                <li className="product-title"><Link to="/">Ranjeet Hanuman</Link></li>

                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={Rehanitation} alt=""></img>
                                    <div className='overlay3'><p>
                                    </p></div>
                                </div>

                                <li className="product-title"><Link to="/">Sudama Nagar</Link></li>

                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>


        </div>

    );
};

export default Ourproduct;