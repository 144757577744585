import React from "react";
// import jewellers from '../assects/Kalyan_jewellers.jpeg'
import house from '../assects/House.jpeg'
// import tolls from '../assects/Kalyan_tolls.jpeg'
import buildings from '../assects/Buildings.jpeg'
// import developers from '../assects/Kalyan_developers.jpeg'
import appartment from '../assects/Appartment.jpeg'
import './Catogires.css'

const Categories = () => {
    return (
        <div>
            <div className="small-slider">
                <div className="small-container">
                    <div className="small-img">
                        <img src={house} alt="kalyan jewellers"></img>
                        <div className="overlay-cat"></div>
                        </div>
                    <div className="small-img">
                        <img src={buildings} alt="Kalyan Tolls"></img>
                        <div className="overlay-cat"></div>
                        </div>
                    <div className="small-img">
                        <img src={appartment} alt="Kalyan Developers"></img>
                        <div className="overlay-cat"></div>
                        </div>
                </div>
            </div>
        </div>
    )
};

export default Categories;