import React from "react";
import './Bicholi.css'
// import logo from '../../assects/Statelogo.webp'

import Vimg1 from '../../assects/Vijay-img.jpg'
import Vimg2 from '../../assects/Vijay-img2.jpg'
import Vimg3 from '../../assects/Vijay-img3.jpg'

const Bicholi = () => {
    return (
        <div className="Bicholi-style">
            <div className="Bicholi-logo">
                {/* <img src={logo} alt="logo"></img> */}
            </div>
            <div>
                <img src="" alt=""></img>
            </div>
            <div className="Bicholi-nav">
            <nav>
                <ul className="B-nav">
                    <li><a href="#">Upcoming Update</a></li>
                    <li><a href="#">Map</a></li>
                    <li><a href="#">News</a></li>
                    <li><a href="#">Secdule a Visit</a></li>
                </ul>
            </nav>
            </div>

            <div className="Bicholi-details">
                <div className="bicho-details">
                    <img src={Vimg1} alt="property image 1 "></img>
                </div>

                <div className="bicho-details">
                    <img src= {Vimg2} alt="property image 2 "></img>
                </div>

                <div className="bicho-details">
                    <img src= {Vimg3} alt="property image 3 "></img>
                </div>
            </div>
        </div>
    );
};

export default Bicholi;