import React from "react";
import './Committed.css';

const commitedTo = () => {
    return (
        <div id="services">
            <div className="committed">
                <div className="committed-h1">
                    <h1>Estate X Is Committed To</h1>
                </div>
                <div className="containers">
                    <div className="box"><h3>Why Choose Us?</h3>
                        <div><p>* Experience and Expertise: With years of experience in the real estate industry, our team has the knowledge and skills to help you make the best property decisions.<br /><br />
* Client-Centered Approach: We put our clients at the heart of everything we do. Your satisfaction is our top priority, and we work diligently to exceed your expectations.<br />
</p></div></div>
                    {/* <div className="box"><h3>100% Ayurvedic</h3>
                <div><p>Pristine Ayurvedic purity, authentically holistic.</p></div></div> */}
                    <div className="box"><h3>Services To All</h3>
                        <div><p>
                        Our dedication to service means we offer personalized attention to each client, tailoring our services to meet their unique needs. From the initial consultation to the finalization of deals and beyond, we provide comprehensive support, making the process smooth and stress-free. We prioritize accessibility, being responsive and available to address any concerns promptly. Additionally, we believe in positively impacting the communities we serve through various engagement and support initiatives.

</p></div></div>
                    <div className="box"><h3>Total Integrity</h3>
                        <div><p>
                        We believe in conducting all transactions with clear and straightforward communication, ensuring there are no hidden fees or surprises. Our team adheres to the highest ethical standards, providing accurate and timely information to help clients make informed decisions. Building and maintaining trust is fundamental to our approach, and we consistently strive to exceed expectations through reliable and ethical practices </p></div></div>
                </div>
            </div>
        </div>
    );
};

export default commitedTo;