import React from "react";
import './Vijay-Nagar.css'
// import logo from '../../assects/Statelogo.webp'
import Vimg1 from '../../assects/Vijay-img.jpg'
import Vimg2 from '../../assects/Vijay-img2.jpg'
import Vimg3 from '../../assects/Vijay-img3.jpg'




const Vijay = () => {
    return (
        <div className="vijay-style">
            <div className="vijay-logo">
                {/* <img src={logo} alt="logo"></img> */}
            </div>
            <div>
                <img src="" alt=""></img>
            </div>
            <div className="Vijay-nav">
            <nav>
                <ul className="V-nav">
                    <li><a href="#">Upcoming Update</a></li>
                    <li><a href="#">Map</a></li>
                    <li><a href="#">News</a></li>
                    <li><a href="#">Secdule a Visit</a></li>
                </ul>
            </nav>
            </div><br />

            <div className="property-details">
                <div className="prop-details">
                    <img src={Vimg1} alt="property image 1 "></img>
                    <p></p>
                </div>

                <div className="prop-details">
                    <img src={Vimg2} alt="property image 2 "></img>
                </div>

                <div className="prop-details">
                    <img src={Vimg3} alt="property image 3 "></img>
                </div>
            </div>
        </div>
    );
};

export default Vijay;