import React from "react";
import './Slider.css'
// import slide1 from '../assects/Kalyan_slider.png'
import centerimg from '../assects/center-img.jpeg'
const slider = () => {

    return (
        <div className="slider">
            <div className="img-slider">
                <div className="slide"><img src={centerimg} alt="this is slide1"></img>
                    </div>
                    <div className="slide-btn">
                </div>
            </div>
        </div>
    );
};

export default slider;