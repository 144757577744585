import React from "react";
import './Supper-Coridoor.css'
import Vimg1 from '../../assects/Vijay-img.jpg'
import Vimg2 from '../../assects/Vijay-img2.jpg'
import Vimg3 from '../../assects/Vijay-img3.jpg'




const Coridoor = () => {
    return (
        <div className="Coridoor-style">
            <div className="Coridoor-logo">
                {/* <img src={logo} alt="logo"></img> */}
            </div>
            <div>
                <img src="" alt=""></img>
            </div>
            <div className="Coridoor-nav">
            <nav>
                <ul className="C-nav">
                    <li><a href="#">Upcoming Update</a></li>
                    <li><a href="#">Map</a></li>
                    <li><a href="#">News</a></li>
                    <li><a href="#">Secdule a Visit</a></li>
                </ul>
            </nav>
            </div><br />

            <div className="property-details">
                <div className="prop-details">
                    <img src={Vimg1} alt="property image 1 "></img>
                    <p></p>
                </div>

                <div className="prop-details">
                    <img src={Vimg2} alt="property image 2 "></img>
                </div>

                <div className="prop-details">
                    <img src={Vimg3} alt="property image 3 "></img>
                </div>
            </div>
        </div>
    );
};

export default Coridoor;