import React from "react";
import './weOffer.css';

const weOffer = () => {
    return (
        <div id="services">
            <div className="committed">
                <div className="committed-h1">
                    <h1>What We offer</h1>
                </div>
                <div className="containers">
                    <div className="box"><h3>Comprehensive Property Listings</h3>
                        <div><p>Whether you are looking for a cozy home, a spacious office, or a lucrative investment property, we offer an extensive range of listings to suit various preferences and budgets.</p></div></div>
                    {/* <div className="box"><h3>100% Ayurvedic</h3>
                <div><p>Pristine Ayurvedic purity, authentically holistic.</p></div></div> */}
                    <div className="box"><h3>Expert Guidance</h3>
                        <div><p>
                        Our team of experienced real estate professionals is here to provide you with valuable insights and advice. We understand the local market trends and will help you navigate through the complexities of buying property. </p></div></div>
                    <div className="box"><h3>Smooth Transactions</h3>
                        <div><p>
                        We handle all the paperwork and legalities involved in property transactions, ensuring a hassle-free experience for you. Our goal is to make the process as seamless as possible, so you can focus on your new beginning. </p></div></div>
                </div>
            </div>
        </div>
    );
};

export default weOffer;