import React from "react";
import aboutus from '../assects/Statelogo.webp'
// import world from './world-environment-earth-day-concept-generative-ai(1).jpg'

import './Getstarted.css';
// import { i } from "vite/dist/node/types.d-aGj9QkWt";

const Getstarted = () => {
    return (
        <div id="aboutus">
            <div className="aboutus">
                <h1 className="abouthead">Get Started Today</h1>
                <div className="about-image">
                    
                    <div className="about-text">
                        <p>
                            Whether you are a first-time buyer or a seasoned investor,Estate X is here to help you achieve your property goals.
                            Contact us today to begin your journey towards finding your desired property.
                            Feel free to reach out to us via phone, email, or through our website. We look forward to partnering with
                            you and turning your property dreams into reality.

                        </p>
                    </div>
                    {/* <img src={aboutus} alt="About Company" /> */}
                </div>
            </div>
        </div>
    );
}

export default Getstarted;