import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components /Navbar';
import Oriantation from './components /oriantation'
import Header from './components /Header';
import Categories from './components /Catogires';
import Slider from './components /Slider';
import Aboutus from './components /Aboutus';
import Ourproduct from './components /OurProjects';
import Committed from './components /Committed';
import WeOffer from './components /weOffer';
import Contact from './components /Contact';
import Getstated from './components /Getstarted';

import Vijay from '../src/components /Property_pages/Vijay-Nagar';
import Coridoor from '../src/components /Property_pages/Supper-Coridoor';
import Bicholi from '../src/components /Property_pages/Bicholi';

function App() {
  return (
    <div className="App">

      <Router>

        <Oriantation />

        <Navbar />

        <Categories />

        <Header />

        <Slider />

        <Ourproduct />
        <Routes>
          <Route path="/Vijay-Nagar" element={<Vijay />} />
          <Route path="/Supper-Coridoor" element={<Coridoor />} />
          <Route path="/Bicholi" element={<Bicholi />} />
        </Routes>
        {/* < Machinery /> */}

        <Aboutus />

        <Committed />

        <WeOffer />

        <Getstated />
        
        <Contact />

      </Router>
    </div>

  );
}

export default App;