import React from "react";
import './contact.css';
import logo from '../assects/Statelogo.webp'
import { FaFacebook, FaInstagram, FaPinterest , FaLinkedin, } from 'react-icons/fa';
const Contact = () => {
    return (
        <div id="contactus">
            <div className="contactbox">
                <div className="contactus"><h2>CONTACT US</h2>
                    <div className="line"></div>
                    <div className="left-contant">Get in touch:<br />
                        phone: 91111104488<br />
                        Fax  : +91-9893475508<br />
                        Email : Shresthrathore08@gmail.com<br />
                        Website: http://EstateX.com</div>
                    <div className="right-contant">
                        Bussines Address<br />
                        Index Enterprises<br />
                        78/79 New siyaganj Indore - 452001 (M .P )</div>
                </div>
                <div className="follo-logo">
                    <div className="follow-us"><h2>FOLLOW US</h2>
                        <div className="line"></div>
                        <div className="icon"><FaFacebook><a href="https://www.facebook.com/profile.php?id=61557690291575"></a></FaFacebook></div>
                        <div className="icon"><FaInstagram><a href="https://www.instagram.com/_forest_wellness_/"></a></FaInstagram></div>
                        {/* <div className="icon"><FaTwitch /></div> */}
                        {/* <div className="icon"><a href="#"></a><FaTwitter /></div>  */}
                        <div className="icon"><a href="https://www.linkedin.com/in/atharva-mahajan-903996303/"></a><FaLinkedin /></div>
                        <div className="icon"><FaPinterest /></div>
                        {/* <div className="icon"><FaYoutube /></div> */}
                    </div>
                    <div className="indexlogo">
                        <div className='brandlogo'>
                            <div className="brand">
                                {/* <h2 className="follow-us">Kalyan Groups</h2> */}
                                {/* <div className="line"></div> */}
                            </div>
                            <img src={logo} alt="COMING SOON" />
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Contact;