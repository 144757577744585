import React from "react";
import aboutus from '../assects/Statelogo.webp'
// import world from './world-environment-earth-day-concept-generative-ai(1).jpg'

import './Aboutus.css';
// import { i } from "vite/dist/node/types.d-aGj9QkWt";

const Aboutus = () => {
    return (
        <div id="aboutus">
            <div className="aboutus">
                <h1 className="abouthead">ABOUT US</h1>
                <div className="about-image">
                    <img src={aboutus} alt="About Company" />
                    <div className="about-text">
                        <p>
                        At Estate X, we are dedicated to helping you find the perfect property that meets
                         your needs and desires. As a trusted name in the real estate industry, we specialize
                          in guiding individuals and businesses through the complex process of purchasing 
                          property, ensuring a smooth and satisfying experience from start to finish.
                          Our MissionOur mission is to provide exceptional real estate services that empower 
                          our clients to make informed decisions and achieve their property ownership dreams.
                           We strive to offer personalized solutions, expert advice, and unwavering support 
                           throughout the property buying journey.

</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Aboutus;