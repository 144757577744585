import React, { useEffect, useState } from 'react';
import './oriantation.css'

const Orientation = () => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const handleOrientationChange = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    isPortrait && (
      <div className="orientation-message">
        Please rotate your phone to landscape mode for a better experience.
      </div>
    )
  );
};

export default Orientation;